import Axios from "axios";

// const URL_API =
//   window.location.origin === "https://www.acres.ph"
//     ? "https://acres.ph"
//     : "http://18.139.117.56";
const AxiosInstance = Axios.create({
  baseURL: `${window.location.origin}/backend/public/api/`,
  // baseURL: "http://localhost:8000/api/",
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

export default AxiosInstance;
